<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-0">
        {{ questionText }}
      </p>
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="pl-6 mt-1">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UPEISafety3',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    questionNumber() {
      return this.taskState.getValueBySymbol('questionNumber').content.value;
    },
    questionText() {
      if (this.questionNumber === 1) {
        return 'How will you make sure you are pouring the correct reagent?';
      } else if (this.questionNumber === 2) {
        return 'What is the proper procedure for mixing a concentrated acid and water?';
      } else if (this.questionNumber === 3) {
        return 'How will you keep a reagent from getting contaminated?';
      } else {
        return 'What should be put in the broken glass box?';
      }
    },
    options1() {
      if (this.questionNumber === 1) {
        return [
          {text: 'By reading the label', value: '1'},
          {text: 'By re-reading the instructions', value: '2'},
          {text: 'By re-reading the label', value: '3'},
          {text: 'All of the above', value: '4'},
        ];
      } else if (this.questionNumber === 2) {
        return [
          {text: 'By adding the water to the acid', value: '1'},
          {text: 'By adding the acid to the water slowly', value: '2'},
          {text: 'By adding the acid to the water quickly', value: '3'},
        ];
      } else if (this.questionNumber === 3) {
        return [
          {
            text: 'Return unused reagent to the bottle',
            value: '1',
          },
          {
            text: 'Leave the reagent bottle uncapped',
            value: '2',
          },
          {
            text: 'Use only the dedicated disposable pipette to dispense it',
            value: '3',
          },
          {
            text: 'Use the pipette from your locker',
            value: '4',
          },
          {
            text: 'Place the stopper on the benchtop while dispensing the reagent',
            value: '5',
          },
        ];
      } else {
        return [
          {text: 'All trash in the lab', value: '1'},
          {text: 'Trash and glass', value: '2'},
          {text: 'Glass only', value: '3'},
          {text: 'Syringes, glass, and trash', value: '4'},
        ];
      }
    },
  },
};
</script>
